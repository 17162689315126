import React, { useState, useRef } from "react";
import Cookies from 'js-cookie';
import { Toast } from "primereact/toast";
import FingerIcon from "../../images/fingerprint-svgrepo-com.svg";




const KycImageCard = ({userData}) => {
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileName, setFileName] = useState("");
    const [image, setImage] = useState(null);
    const toast = useRef(null);
    const Auth_Cookie = Cookies.get('avaAuth');
    const [miniLoader, setMiniLoader] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            setFileName(file.name);
        } else {
            setImage(null);
            setPreviewUrl(null);
            setFileName("");
        }
    };


    const UploadFile = async (e) => {
        e.preventDefault();
        if (!image) {
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'لطفا یک تصویر انتخاب کنید', life: 5000 });
            return;
        }
        setMiniLoader(true);
        try {
            const formData = new FormData();
            formData.append('Image', image);
            formData.append('Type', 'Identify');

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Auth_Cookie}`,
                },
                body: formData,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_BASE_URL + '/public/api/v1/file/upload.php', requestOptions);
            const data = await response.json();
            if (data.status === 200) {
                toast.current.show({ severity: 'success', summary: 'عملیات موفق', detail: 'تصویر شما با موفقیت بارگذاری شد', life: 7000 });
                window.location.reload();
            } else {
                toast.current.show({ severity: 'error', summary: 'خطا', detail: data.message, life: 5000 });
                setMiniLoader(false);
            }
        } catch (error) {
            console.log(error);
            setMiniLoader(false);
        }
    };


    return (
        <div className="card mt-6 p-7">
            <div className="card_title  flex items-center">
                <span className="card_heading_text">تصویر
                    <small> (الزامی)</small>
                </span>
                <span className="card_heading_icon mr-auto">
                    <img src={FingerIcon} alt="AvaPay24" width={24} />
                </span>
            </div>
            <Toast ref={toast} />

            <div className="p-0">
                {userData && userData[0] && userData[0]["Image"] === null ? (
                    <form action="#" onSubmit={UploadFile}>
                        <div className="IdentifyHint">
                            <span className='IdentifyTitle' >راهنما گرفتن عکس</span>
                            <ul>
                                <li>ابتدا فایل ضمیمه شده را دانلود کرده و پرینت بگیرید. سپس برگه پرینت شده و امضا شده را در دست راست خود نگه دارید به طوری که متن به وضوح قابل خواندن باشد. </li>
                                <li>کارت ملی و کارت بانکی خود را در دست چپ خود نگه دارید.</li>
                                <li>مطمئن شوید که چهره شما، متن امضا شده، کارت ملی و کارت بانکی همگی به وضوح در عکس قابل مشاهده باشند.</li>
                            </ul>
                        </div>
                        <p className="small_hint" >
                            ابتدا فایل زیر را دانلود کرده و طبق دستور عمل عکس نهایی را آپلود کنید
                            <small className='Hint' >
                                (توجه نمایید اطلاعات درج شده در تصویر باید با اطلاعات ثبت شده در سایت یکسان باشد.)
                            </small>
                        </p>
                        <a target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 w-full my-5 button_info" href="https://avapay24.co/public/files/AvaPayAUTH_Form.pdf">
                            دانلود فایل احراز هویت
                        </a>
                        <div
                            id="FileUpload"
                            className="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                        >
                            <input
                                // required
                                type="file"
                                accept="image/*"
                                className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                                onChange={handleFileChange}
                            />
                            <div className="flex flex-col items-center justify-center space-y-3">
                                <span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                                            fill="#3C50E0"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                                            fill="#3C50E0"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                                            fill="#3C50E0"
                                        />
                                    </svg>
                                </span>
                                <p>
                                    <span className="text-primary">کلیک کنید</span> یا
                                    در اینجا رها کنید
                                </p>
                                <p className="mt-1.5">PNG,JPG</p>
                                <p>(max, 2MB)</p>
                            </div>


                            {previewUrl && (
                                <div className="mb-5 preview">
                                    <p className='text-center mb-2' ><a href={previewUrl} target="_blank" rel="noopener noreferrer">{fileName}</a></p>
                                    <img src={previewUrl} alt="Preview" className="mt-3" style={{ maxWidth: '100%', height: 'auto' }} />
                                </div>
                            )}
                        </div>


                        {miniLoader ? (
                            <div className="mini_loader pt-0 mr-4">
                                <div className="loader_small"></div>
                            </div>
                        ) : (
                            <div className="flex justify-end gap-4.5">
                                <button
                                    className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90 button w-full mt-6"
                                    type="submit"
                                >
                                    ثبت فایل احراز هویت
                                </button>
                            </div>
                        )}


                    </form>
                ) : (
                    <>
                        <div className="_blank"></div>
                    </>
                )}
            </div>
        </div>
    )
}

export default KycImageCard;