import React, { useState, useRef } from "react";
import Cookies from 'js-cookie';
import { Toast } from "primereact/toast";
import FingerIcon from "../../images/fingerprint-svgrepo-com.svg";

const PersonalInfoCard = ({ userData, isIdentified }) => {
    const toast = useRef(null);
    const Auth_Cookie = Cookies.get('avaAuth');
    const [miniLoader, setMiniLoader] = useState(false);
    const [formData, setFormData] = useState({
        FirstName: userData[0]?.FirstName || '',
        LastName: userData[0]?.LastName || '',
        Mobile: userData[0]?.Mobile || '',
        NationalCode: userData[0]?.NationalCode || '',
        BirthDate: userData[0]?.BirthDate || '',
        Card: userData[0]?.Card || '',
        Hesab: userData[0]?.Hesab || '',
        Sheba: userData[0]?.Sheba || '',
        Password: '',
    });
    const [changedFields, setChangedFields] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Update formData state
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Track changed fields
        setChangedFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };




    const UpdateUser = async (event) => {
        event.preventDefault();

        // Ensure user data exists
        if (!userData || userData.length === 0) {
            toast.current.show({
                severity: "error",
                summary: "خطا",
                detail: "اطلاعات کاربر یافت نشد",
                life: 7000,
            });
            return;
        }

        // Check if there are changes to update
        if (Object.keys(changedFields).length === 0) {
            toast.current.show({
                severity: "info",
                summary: "هیچ تغییری یافت نشد",
                detail: "شما تغییری در اطلاعات کاربر ایجاد نکردید.",
                life: 7000,
            });
            return;
        }

        // Password change validation
        if (
            changedFields["new-password"] !== undefined
        ) {
            // Check if current-password not entered
            if (changedFields["current-password"] === undefined || changedFields["current-password"] === "") {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: "لطفا رمز عبور فعلی خود را وارد کنید.",
                    life: 7000,
                });
                return;
            }

            if (changedFields["new-password"] !== changedFields["confirm-password"]) {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: "رمز عبور جدید و تکرار آن باید یکسان باشند.",
                    life: 7000,
                });
                return;
            }

            changedFields['Password'] = changedFields['new-password'];
        }

        // Filter out empty values in changedFields (if needed)
        const filteredChangedFields = Object.fromEntries(
            Object.entries(changedFields).filter(([key, value]) => value !== "" && value !== null)
        );

        if (Object.keys(filteredChangedFields).length === 0) {
            toast.current.show({
                severity: "info",
                summary: "هیچ تغییری یافت نشد",
                detail: "هیچ تغییری برای ارسال وجود ندارد.",
                life: 7000,
            });
            return;
        }

        try {
            setMiniLoader(true);

            // Sending PUT request to update user data
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Auth_Cookie}`,
                    },
                    body: JSON.stringify(filteredChangedFields),
                    redirect: "follow",
                }
            );

            const data = await response.json();

            if (response.ok) {
                toast.current.show({
                    severity: "success",
                    summary: "موفق",
                    detail: "اطلاعات شما با موفقیت به روز شد",
                    life: 7000,
                });
            } else {
                if (response.status === 401) {
                    toast.current.show({
                        severity: "error",
                        summary: "عدم دسترسی",
                        detail: "لطفا وارد حساب کاربری خود شوید.",
                        life: 7000,
                    });
                    // Optionally, redirect to login page if token is invalid
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "خطا",
                        detail: data.message || "خطایی رخ داد",
                        life: 7000,
                    });
                }
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
            toast.current.show({
                severity: "error",
                summary: "خطا",
                detail: "خطایی رخ داد. لطفا دوباره تلاش کنید.",
                life: 7000,
            });
        } finally {
            setMiniLoader(false);
        }
    };



    return (
        <div>
            <Toast ref={toast} />
            <div className="card mt-6 p-7">
                <div className="card_title  flex items-center">
                    <span className="card_heading_text">سایر اطلاعات <small>(الزامی)</small></span>
                    <span className="card_heading_icon mr-auto">
                        <svg
                            width={25}
                            height={25}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path data-gb
                                d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                                stroke="#2554FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>

                <form onSubmit={UpdateUser}>
                    <div className="title_lines mb-6">اطلاعات فردی</div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pl-3">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            نام *
                        </label>
                        <input
                            defaultValue={userData && userData[0] && userData[0]["FirstName"]}
                            name="FirstName"
                            onChange={handleInputChange}
                            type="text"
                            placeholder="Loading..."
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                            autoComplete="given-name"
                            disabled={isIdentified}
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            نام خانوادگی *
                        </label>
                        <input
                            placeholder="Loading..."
                            defaultValue={userData && userData[0] && userData[0]["LastName"]}
                            name="LastName"
                            onChange={handleInputChange}
                            type="text"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                            autoComplete="family-name"
                            disabled={isIdentified}
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pl-3">
                        <label
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            پست الکترونیکی
                            <small>(غیرقابل تغییر)</small>
                        </label>
                        <input
                            placeholder="Loading..."
                            name="Email"
                            defaultValue={userData && userData[0] && userData[0]["Email"]}
                            type="text"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                            readOnly
                            autoComplete="email"
                            disabled
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            شماره همراه *
                        </label>
                        <input
                            placeholder="Loading..."
                            defaultValue={userData && userData[0] && userData[0]["Mobile"]}
                            name="Mobile"
                            onChange={handleInputChange}
                            type="text"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pl-3">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            کد ملی *
                        </label>
                        <input
                            defaultValue={userData && userData[0] && userData[0]["NationalCode"]}
                            name="NationalCode"
                            onChange={handleInputChange}
                            type="text"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                            autoComplete="off"
                            disabled={isIdentified}
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                        <label
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            تاریخ تولد
                            <small>(میلادی)</small>
                            *
                        </label>

                        <input
                            dir="rtl"
                            defaultValue={userData && userData[0] && userData[0]["BirthDate"]}
                            name="BirthDate"
                            onChange={handleInputChange}
                            type="date"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                            disabled={isIdentified}
                        />
                    </div>


                    <div className="title_lines mb-6">اطلاعات بانکی</div>

                    <div className="mb-7 w-full">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            شماره حساب *
                        </label>
                        <input
                            defaultValue={userData && userData[0] && userData[0]["Hesab"]}
                            name="Hesab"
                            onChange={handleInputChange}
                            type="tel"
                            autoComplete="off"
                            dir="rtl"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                        />
                    </div>

                    <div className="mb-7 w-full">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            شماره شبا *
                        </label>
                        <input
                            defaultValue={userData && userData[0] && userData[0]["Sheba"]}
                            name="Sheba"
                            onChange={handleInputChange}
                            type="text"
                            autoComplete="off"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            required
                        />
                    </div>


                    {miniLoader ? (
                        <div className="mini_loader pt-0 mr-4">
                            <div className="loader_small"></div>
                        </div>
                    ) : (
                        <button className="button" type="submit">
                            بروزرسانی اطلاعات
                        </button>
                    )}
                </form>
            </div>


            <div className="card mt-6 p-7">
                <div className="card_title  flex items-center">
                    <span className="card_heading_text">امنیت</span>
                    <span className="card_heading_icon mr-auto">
                        <img src={FingerIcon} alt="AvaPay24" width={24} />
                    </span>
                </div>
                <form onSubmit={UpdateUser}>
                    <div className="mb-7 w-full">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            رمز فعلی
                        </label>
                        <input
                            name="current-password"
                            onChange={handleInputChange}
                            type="password"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            autoComplete="current-password"
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            رمز جدید
                        </label>
                        <input
                            name="new-password"
                            onChange={handleInputChange}
                            type="password"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            autoComplete="new-password"
                        />
                    </div>

                    <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                        <label
                            htmlFor="rate"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            تکرار رمز
                        </label>
                        <input
                            name="confirm-password"
                            onChange={handleInputChange}
                            type="password"
                            className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                            autoComplete="new-password"
                        />
                    </div>
                    {miniLoader ? (
                        <div className="mini_loader pt-0 mr-4">
                            <div className="loader_small"></div>
                        </div>
                    ) : (
                        <button className="button" type="submit">
                            تغییر رمزعبور
                        </button>
                    )}
                </form>
            </div>
        </div>

    )
}

export default PersonalInfoCard;