import React, { useRef, useState, useEffect } from 'react';
import logo from '../images/key.svg';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';
import { useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
    const toast = useRef(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [miniLoader, setMiniLoader] = useState(false);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        if (!token) {
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'توکن بازیابی یافت نشد.', life: 5000 });
        }
    }, [token]);

    const resetPassword = async (event) => {
        event.preventDefault();

        // Validate input
        if (!password || !confirmPassword) {
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'لطفا هر دو فیلد را پر کنید.', life: 5000 });
            return;
        }

        if (password !== confirmPassword) {
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'رمز عبور و تکرار آن یکسان نیستند.', life: 5000 });
            return;
        }

        try {
            setMiniLoader(true);

            // Make API request
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/auth/reset-password.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    "token": token,
                    "password": password,
                }),
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status === 200) {
                setSuccess(true);
                toast.current.show({ severity: 'success', summary: 'تبریک', detail: 'رمز عبور با موفقیت تغییر کرد.', life: 5000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'خطا', detail: data.message, life: 5000 });
                setMiniLoader(false);
            }
        } catch (error) {
            console.error(error);
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'خطایی در سرور رخ داده است.', life: 5000 });
        }
    };

    return (
        <>
            <div className='auth_back'>
                <div className="lg:flex auth_holder">
                    <div className="auth_box">
                        <Toast ref={toast} />
                        <div className="flex items-center mb-10">
                            <img className='ml-3' src={logo} width={50} alt="avapayLogin" />
                            <span className="spacer"></span>
                            <div className="navbar_brand m-0">
                                <h1 className="site_title">آواپی</h1>
                                <small>بازیابی رمز عبور</small>
                            </div>
                        </div>
                        {success ? (
                            <div className="text-center mt-5">
                                <p className="text-lg text-gray-900 dark:text-white">رمز عبور شما با موفقیت تغییر کرد.
                                    <br />اکنون می‌توانید وارد حساب کاربری خود شوید.</p>
                                <a className='link_to' href="/login">ورود به آواپی۲۴</a>
                            </div>
                        ) : (
                            <form onSubmit={resetPassword}>
                                {!token && (
                                    <div className="text-center mt-5">
                                        <p className="text-lg text-red-600 dark:text-red-400">توکن معتبر نیست یا یافت نشد.</p>
                                    </div>
                                )}
                                {token && (
                                    <>
                                        <div className="mb-5">
                                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">رمز عبور جدید</label>
                                            <input
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                type="password"
                                                name='password'
                                                autoComplete='new-password'
                                                id="password"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                placeholder="رمز عبور جدید"
                                                required
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">تکرار رمز عبور جدید</label>
                                            <input
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                type="password"
                                                id="confirmPassword"
                                                autoComplete='new-password'
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                placeholder="تکرار رمز عبور جدید"
                                                required
                                            />
                                        </div>
                                        {miniLoader ? (
                                            <div className="mini_loader pt-5 mr-4">
                                                <div className="loader_small"></div>
                                            </div>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full mt-5 button"
                                            >
                                                تغییر رمز عبور
                                            </button>
                                        )}
                                    </>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
