import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./views/dashboard";
import Login from "./views/login";
import ForgotPassword from "./views/forgotPassword";
import Signup from "./views/signup";
import Trades from "./views/trade";
import Identify from "./views/identify";
import Payments from "./views/payments";
import Rates from "./views/rates";
import Tickets from "./views/tickets";
import UsdtTransActions from "./views/usdtTrns";
import ResetPassword from "./views/resetPassword";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/trades" element={<Trades />} />
                <Route path="/identify" element={<Identify />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/rates" element={<Rates />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/usdtTrns" element={<UsdtTransActions />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
