import React, { useEffect, useState, useRef } from "react";
import Cookies from 'js-cookie';
import { timeAgo } from '../utils/timeAgo';
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";

const Chat = ({ chatData }) => {
    const Auth_Cookie = Cookies.get('avaAuth');
    const [text, setText] = useState('');
    const [replies, setReplies] = useState([]);
    const [ContentLoading, setContentLoading] = useState(true);
    const [miniLoader, setMiniLoader] = useState(false);
    const toast = useRef(null);

    // Function to fetch replies
    const fetchReplies = async () => {
        setContentLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/reply.php?id=${chatData['id']}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Auth_Cookie}`,
                    },
                },
            );
            if (response.ok) {
                const data = await response.json();
                if (data.status === 200) {
                    setReplies(data.message);
                } else {
                    console.log('Error fetching replies!');
                }
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setContentLoading(false);
        }
    };

    useEffect(() => {
        fetchReplies();
    }, []);

    const addReply = async (e) => {
        e.preventDefault();
        setMiniLoader(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/reply.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${Auth_Cookie}`,
                },
                body: new URLSearchParams({
                    "Text": text,
                    "TicketId": chatData['id'],
                    "TargetUser": chatData['UserId']
                }),
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status === 200) {
                toast.current.show({
                    severity: "success",
                    summary: "موفق",
                    detail: data.message,
                    life: 5000,
                });
                setText('');  // Clear the input field
                fetchReplies(); // Fetch the replies again
                setMiniLoader(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: data.message,
                    life: 5000,
                });
                setMiniLoader(false);
            }
        } catch (error) {
            if (error instanceof Error) {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: error.message,
                    life: 5000,
                });
                setMiniLoader(false);
            } else {
                console.log('An unexpected error occurred');
            }
        }



    };


    return (
        <>
            <Toast ref={toast} />
            {ContentLoading ? (
                <div className="lg:grid lg:grid-cols-1 gap-5 mt-6 card p-0 lg:w-1/3">
                    <span className="content_loader"></span>
                </div>
            ) : (
                <div className="card p-0 mt-6 mb-5 lg:w-1/3">
                    <div className="p-5 pb-0">
                        <div className="ticket_title">{chatData['Title']}</div>
                    </div>
                    <div className="flex flex-col items-center justify-center chat_area">
                        <div className="flex flex-col p-4 flex-grow w-full max-w-md bg-white rounded-lg overflow-hidden">
                            <div className="flex flex-col flex-grow h-0 p-4 overflow-auto box_back">
                                <div className="flex w-full mt-2 justify-start" dir="rtl">
                                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 mx-3 chat_icon force-hidden">
                                        <i className="bx bx-support"></i>
                                    </div>
                                    <div>
                                        <div className="p-3 rounded-r-lg rounded-bl-lg bg-blue-600">
                                            {chatData.FilePath && (
                                                <img src={`https://panel.avapay24.co/AvaPay_WebService/uploads/${chatData.FilePath.split('/').pop()}`} alt="attachment" className="w-32 h-32 object-cover rounded-lg mx-3" />
                                            )}
                                            <p className="text-sm">{chatData.Text}</p>
                                        </div>
                                        <span className="text-xs text-gray-500 leading-none">{timeAgo(chatData.Date)}</span>
                                    </div>
                                </div>
                                {replies.map((reply, index) => (
                                    <div key={index} className={`flex w-full mt-2 ${reply.Sender === 'admin' ? 'rep_box' : 'justify-start'}`} dir={reply.Sender === 'admin' ? 'ltr' : 'rtl'}>
                                        <div className={`flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 mx-3 chat_icon ${reply.Sender === 'admin' ? '' : 'force-hidden'}`}>
                                            <i className='bx bx-support'></i>
                                        </div>
                                        <div>
                                            <div className={`p-3 rounded-r-lg rounded-bl-lg ${reply.Sender === 'admin' ? 'bg-gray-300' : 'bg-blue-600'}`}>
                                                <p className="text-sm">{reply.Text}</p>
                                            </div>
                                            <span className="text-xs text-gray-500 leading-none">{timeAgo(reply.Date)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <form className="bg-gray-300 p-4 mt-4 flex" onSubmit={addReply}>
                                <input
                                    required
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    className="flex items-center h-10 w-full rounded px-3 text-sm"
                                    type="text"
                                    placeholder="بنویسید..."
                                />
                                {miniLoader ? (
                                    <div className="mini_loader pt-0 mr-8 pr-7">
                                        <div className="loader_small"></div>
                                    </div>
                                ) : (
                                    <button type="submit" className="button_small mr-3">ارسال</button>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Chat;
