
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../images/key.svg';
import Cookies from 'js-cookie';
// toast lib ==>
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';
import CryptoJS from 'crypto-js';
import { setUser, clearUser } from '../features/userSlice';

const Login = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState([]);
    const [password, setPassword] = useState([]);
    const toast = useRef(null);
    const navigate = useNavigate();
    const [miniLoader, setMiniLoader] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    const encrypt = (text) => {
        return CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY).toString();
    };

    function checkRole(param) {
        if (param == 0) {
            return encrypt('user');
        } else if (param == 1) {
            return encrypt('admin');
        } else {
            return encrypt('error');
        }
    }

    const loginAction = async (event) => {
        event.preventDefault();

        try {
            setMiniLoader(true)

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/auth/login.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }, body: new URLSearchParams({
                    "Email": email,
                    "Password": password
                }),
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status == 200) {
                Cookies.set('avaAuth', data.token, { expires: 1 });
                Cookies.set('theme', checkRole(data.role), { expires: 1 });
                Cookies.set('user_FirstName', data.name, { expires: 1 });
                Cookies.set('user_Email', data.email, { expires: 1 });


                dispatch(setUser(data));


                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Login success', life: 2000 });
                navigate('/');

            } else {
                //  console.log(data.message)
                toast.current.show({ severity: 'error', summary: 'Error', detail: data.message, life: 5000 });
                setMiniLoader(false)

            }

        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };

    return (
        <>
            {isLoading && <div><div className="loading_wrapper"><span className="loader"></span></div></div>}
            <div className='auth_back' >

                {/* <div className="loading_wrapper"><span className="loader"></span></div> */}
                <div className="lg:flex auth_holder" >
                    <div className="auth_box">
                        <Toast ref={toast} />
                        <div className="flex items-center mb-10">
                            <img className='ml-3' src={logo} width={50} alt="avapayLogin" />
                            <span className="spacer"></span>
                            <div className="navbar_brand m-0"><h1 className="site_title">آواپی</h1><small>ورود به پنل کاربری</small></div>
                        </div>
                        <form onSubmit={loginAction} >
                            <div className="mb-5">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">پست الکترونیک</label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">رمزعبور</label>
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password" id="password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="*****" required />
                            </div>

                            {miniLoader ? (
                                <div className="mini_loader pt-5 mr-4">
                                    <div className="loader_small"></div>
                                </div>
                            ) : (
                                <>
                                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full mt-5 button">ورود به حساب کاربری</button>
                                    <div className='auth_links'>
                                        <a href="/signup">ثبت نام در آواپی۲۴</a>
                                        <span>●</span>
                                        <a href="/forgot-password">بازیابی رمز عبور</a>
                                    </div>
                                </>
                            )}

                        </form>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
