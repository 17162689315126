
import React, { useRef } from 'react';
import { useState } from 'react';
import logo from '../images/key.svg';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';

const ForgotPassword = () => {

    const [email, setEmail] = useState([]);
    const toast = useRef(null);
    const [success, setSuccess] = useState(false);
    const [miniLoader, setMiniLoader] = useState(false);

    const forgotPW = async (event) => {
        event.preventDefault();

        try {
            setMiniLoader(true)

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/auth/forget-password.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }, body: new URLSearchParams({
                    "Email": email
                }),
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status == 200) {
                setSuccess(true);
            } else {
                toast.current.show({ severity: 'error', summary: 'خطا', detail: data.message, life: 5000 });
                setMiniLoader(false)
            }

        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };

    return (
        <>
            <div className='auth_back' >
                <div className="lg:flex auth_holder" >
                    <div className="auth_box">
                        <Toast ref={toast} />
                        <div className="flex items-center mb-10">
                            <img className='ml-3' src={logo} width={50} alt="avapayLogin" />
                            <span className="spacer"></span>
                            <div className="navbar_brand m-0"><h1 className="site_title">آواپی</h1><small>بازیابی رمز عبور</small></div>
                        </div>
                        {success ? (
                            <div className="text-center mt-5">
                                <p className="text-lg text-gray-900 dark:text-white">ایمیل بازیابی رمز عبور برای شما ارسال شد.
                                    <br />لطفاً ایمیل خود را بررسی کنید.</p>
                            </div>
                        ) : (
                            <form onSubmit={forgotPW} >
                                <div className="mb-5">
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">پست الکترونیک</label>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                                </div>
                                {miniLoader ? (
                                    <div className="mini_loader pt-5 mr-4">
                                        <div className="loader_small"></div>
                                    </div>
                                ) : (
                                    <>
                                        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full mt-5 button">بازیابی رمز عبور</button>
                                        <div className='auth_links'>
                                            <a href="/login">ورود به آواپی۲۴</a>
                                            <span>●</span>
                                            <a href="/signup">ثبت نام در آواپی۲۴</a>
                                        </div>
                                    </>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
