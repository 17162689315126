import React, { useState, useRef } from "react";
import Cookies from 'js-cookie';

// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";


const Chat = ({ chatData }) => {

    const Auth_Cookie = Cookies.get('avaAuth');
    // chat vars =>
    const [title, setTitle] = useState([]);
    const [text, setText] = useState([]);
    const [miniLoader, setMiniLoader] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null); // State to store the file name
    const [filePreview, setFilePreview] = useState(null); // State to store the file preview

    const toast = useRef(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setFilePreview(URL.createObjectURL(selectedFile)); // Create a preview URL
    };

    const addTicket = async (e) => {
        e.preventDefault();
        setMiniLoader(true)
        try {
            const formData = new FormData();
            formData.append('Text', text);
            formData.append('Title', title);
            if (file) {
                formData.append('file', file);
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/ticket.php`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Auth_Cookie}`,
                },
                body: formData,
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status === 200) {
                toast.current.show({
                    severity: "success",
                    summary: "موفق",
                    detail: data.message,
                    life: 5000,
                });
                window.location.reload();
                // setMiniLoader(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: data.message,
                    life: 5000,
                });
                setMiniLoader(false);
            }

        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: "خطا",
                detail: "An error occurred while submitting the ticket.",
                life: 5000,
            });
            setMiniLoader(false);
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="card p-6 mt-6 w-full mb-5 w-full">
                <h1 className="section_title">افزودن تیکت جدید</h1>

                <form onSubmit={addTicket} className="mt-6" >
                    <div className="lg:flex">
                        <div className="mb-5 lg:w-1/3 lg:pl-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">عنوان تیکت</label>
                            <input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="عنوان تیکت" required type="text" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" />

                            {miniLoader ? (
                                <div className="mini_loader pt-7">
                                    <div className="loader_small"></div>
                                </div>
                            ) : (
                                <button className="button w-full mt-4" type="submit" >ثبت و ارسال</button>
                            )}
                        </div>
                        <div className="mb-5 lg:w-2/3 lg:pr-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">متن تیکت</label>
                            <textarea
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                required rows="5" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="متن تیکت خود را بنویسید ..."></textarea>
                            <label className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">الصاق عکس</label>
                            <label className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white flex items-center justify-center p-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 100 100" id="attachment">
                                    <g id="_x37_7_Essential_Icons">
                                        <path id="Attachment" d="M18.8 85.1c-7.8-7.8-7.8-20.5 0-28.3L63.1 13c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8L38.6 76.7c-3.1 3.1-8.2 3.1-11.3 0-3.1-3.1-3.1-8.2 0-11.3l22.3-21.8c.8-.8 2.1-.8 2.8 0 .8.8.8 2.1 0 2.8L30.2 68.2c-1.5 1.5-1.5 4.1 0 5.6 1.6 1.6 4.1 1.6 5.7 0L80.2 30c3.9-3.9 3.9-10.2 0-14.1-3.9-3.9-10.2-3.9-14.1 0L21.7 59.7c-6.2 6.2-6.2 16.4 0 22.6 6.3 6.2 16.4 6.2 22.6 0l38.3-37.8c.8-.8 2.1-.8 2.8 0 .8.8.8 2.1 0 2.8L47.1 85.2c-7.8 7.7-20.4 7.8-28.3-.1z"></path>
                                    </g>
                                    <g id="Info">
                                        <path id="BORDER" fill="#00F" d="M664-510v1684h-1784V-510H664m8-8h-1800v1700H672V-518z"></path>
                                    </g>
                                </svg>
                                <span>جهت الصاق عکس اینجا کلیک کنید</span>
                                <input type="file" onChange={handleFileChange} className="hidden" />
                            </label>
                            {fileName && (
                                <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                    Selected file: {fileName}
                                </div>
                            )}
                            {filePreview && (
                                <div className="mt-4">
                                    <img src={filePreview} alt="Selected File Preview" className="max-w-full h-auto rounded-lg border border-gray-300" />
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Chat;
